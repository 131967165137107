export const SET_JWT_TOKEN = "setJwtToken";

export const SET_USER = "setUser";
export const LOADING_USER = "loadingUser";
export const SET_SUCCESS_MESSAGE = "setSuccessMessage";
export const SET_ERROR_MESSAGE = "setErrorMessage";
export const SEND_PASSWORD_CONFIRMATION = "sendPasswordConfirmation"
export const SEND_REGISTRATION_CONFIRMATION = "sendRegistrationConfirmation"

export const SET_PORTAL = "setPortal"
export const CLEAR_PORTAL = "clearPortal"

export const SET_DNA_TOKEN = "setDNAToken"

export const SET_SONGS = "setSongs"
export const SET_PLAYER_SONG = "setPlayerSong"
export const SET_NEXT_SONG = "setNextSong"
export const SET_PLAYER_STATE = "setPlayerState"
export const LOADING_SONGS = "loadingSongs"
export const SET_ANALYZING = "analyzing"
export const ADD_SONG = "addSong"

export const REMOVE_SONG = "removeSong"

export const SONGS_REDRAW = "songsRedraw"

export const RESET_PAGINATOR_HAS_CHANGED = "RESET_PAGINATOR_HAS_CHANGED"


export const SET_LEADERBOARD = "leaderboard"

export const LOG_OUT = "logOut";

export const MUSIC_KIT_PLAYLIST_SET_TRACKS = 'MUSIC_KIT_PLAYLIST_SET_TRACKS';
export const MUSIC_KIT_PLAYLIST_SET_IS_LOADING = 'MUSIC_KIT_PLAYLIST_SET_IS_LOADING';
export const MUSIC_KIT_PLAYLIST_SET_IS_APPLE_AUTHORIZED = 'MUSIC_KIT_PLAYLIST_SET_IS_APPLE_AUTHORIZED';
export const MUSIC_KIT_PLAYLIST_SET_META = 'MUSIC_KIT_PLAYLIST_SET_META';

