import React from 'react';
import {NavLink} from 'react-router-dom';

export const TFlex = ({children}) => (
    <div role="main" className="flex-grow">{children}</div>
)

export const TContainer = ({children}) => (
    <div className="max-w-4xl p-1 mx-auto flex flex-col">{children}</div>
)

const Loader = ({loadingText = null}) => (<div className="animate-pulse flex space-x-4">
    <div className="flex-1 space-y-4 py-1">
        <div className="space-y-2">
            <div className="h-4 rounded">{loadingText ? loadingText : "Loading..."}</div>
            <div className="h-4 rounded"></div>
        </div>
    </div>
</div>)

export const TBlock = ({children, loading = false, loadingText = null, ...props}) => {

    return <div className="block text-center p-2" {...props}>
        {loading ? <Loader loadingText={loadingText}/> : children}
    </div>
}

export const TSolidBox = ({children, loading = false, loadingText = null, ...props}) => {
    return (<div className="flex items-center justify-left rounded-lg
  border-black border-2 p-2 text-left" {...props}>
        {loading ? <Loader loadingText={loadingText}/> : children}
    </div>)
}

export const TLink = ({to, exact = false, children, ...props}) => {
    return (<NavLink className="block mt-4 lg:inline-block lg:mt-0 hover:text-white mr-4" to={to} exact={exact} {...props}>{children}</NavLink>)
}

// export const TDottedBox = ({dropzone = false, primary = true, secondary = false, hasPointer = false, padding = 2, InputElement, children, ...props}) => {

//   const classToApply = "block rounded-lg "

//   if (secondary) {
//     classToApply += "border-gray-500 "
//   } else if (primary){
//     classToApply += "border-black "
//   }

//   classToApply += "p-"+padding+" text-center " + (secondary ? "text-gray-500 ": " ") + "mx-auto" + (hasPointer ? " cursor-pointer" : "")

//   if (dropzone) {
//     return (

//     )
//   }  


//   block rounded-lg border-black border-dashed border-2 p-2 text-center
//   block rounded-lg border-gray-500 border-dashed border-2 p-6 text-center text-gray-500 mx-auto cursor-pointer

// }

export const TBtn = (props) => (
    <button className="bg-red-700 rounded text-white font-medium cursor-pointer px-4 py-2" {...props} />
)

// HEADINGS

export const TPrimaryHeading = (props) => <span className="text-3xl font-bold tracking-widest font-display text-red-500"
                                                style={{letterSpacing: ".2em", color: "#c53030"}} {...props} />
export const TSecondaryHeading = (props) => <span className="text-2xl font-bold font-display" style={{color: "#666666"}} {...props} />

// EMPTY SPACES

export const TEmptySpace = ({width = null, height = null}) => {
    let c = ""
    if (width) c += "w-" + width
    if (height) c += " h-" + height

    return (
        <div className={c}></div>
    )
}