import React, {useEffect} from 'react';
import {isMobileSafari, isSafari} from "react-device-detect";
import {store} from "../store/Store";

export const MusicKitInit = () => {
    //console.log('MusicKit initializing')
    if (!isAutoplayEnabled()) {
        document.body.addEventListener('touchstart', function () {
            /**
             * Important hack for Safari
             */
            let empty = new Audio("audio/empty.mp3");
            empty.play().then(_ => {
                empty.pause();
                empty.currentTime = 0;
            });
        }, false);
    }
}

export const isAutoplayEnabled = () => {
    return !(isSafari || isMobileSafari);
}

export const clearQueue = () => {
    const music = window.MusicKit.getInstance();
    music.clearQueue();
}

export const searchSong = async (artistTitle) => {
    if (artistTitle) {
        try {
            const music = window.MusicKit.getInstance();
            const lookup = artistTitle.replace(/ *\([^)]*\) */g, "");//removing parenthesis and content
            const queryParameters = {term: lookup, types: ['songs'], l: 'en-us'};
            const response = await music.api.music('/v1/catalog/{{storefrontId}}/search', queryParameters);

            if (response.data.results && response.data.results.songs && response.data.results.songs.data) {
                return response.data.results.songs.data[0];
            } else {
                // //If not found at the first place, retry after cleaning artistTitle field
                // let cleanedArtistTitle = artistTitle.replace(/ *\([^)]*\) */g, "");//removing parenthesis and content
                // return searchSong(cleanedArtistTitle);
                return false;
            }
        } catch (error) {
            errorHandler(error, 'searchSong');
        }
    }
    return false;
}


export const getPlaylist = async () => {
    const music = window.MusicKit.getInstance();
    try {
        const state = store.getState();

        if (state.musicKit.playlist) {
            return await Promise.resolve(state.musicKit.playlist);
        } else {
            return music.authorize().then(async () => {
                const queryParameters = {l: 'en-us'};
                const response = await music.api.music('/v1/me/library/playlists', queryParameters);
                const responseData = response.data.data;

                const playlist = responseData.find(obj => obj.attributes.name === process.env.REACT_APP_APPLE_PLAYLIST_NAME);

                if (!playlist) {
                    return await createPlaylist();
                } else {
                    return await Promise.resolve(playlist);
                }
            });
        }


    } catch (error) {
        errorHandler(error, 'getPlaylist');
    }
}


export const getSongsFromPlaylist = async () => {
    const music = window.MusicKit.getInstance();

    if (music.isAuthorized) {
        return getPlaylist().then(async playlist => {
                try {
                    const response = await fetch(`https://api.music.apple.com/v1/me/library/playlists/${playlist.id}/tracks`, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ` + music.developerToken,
                            'Music-User-Token': music.musicUserToken
                        }
                    });

                    if (response.status === 404) {
                        return [];
                    } else {
                        const responseBody = await response.json();
                        if (responseBody.data) {
                            return responseBody.data;
                        } else {
                            errorHandler('Fail to get songs from playlist', 'getSongsFromPlaylist');
                        }
                    }
                } catch (error) {
                    errorHandler(error, 'getSongsFromPlaylist');
                }
                return [];
            }
        );
    }
    return [];
}

export const addToPlaylist = async (songLookup) => {
    const music = window.MusicKit.getInstance();

    if (music.isAuthorized) {
        return searchSong(songLookup).then(async song => {
            return getPlaylist().then(async playlist => {
                const response = await fetch(`https://api.music.apple.com/v1/me/library/playlists/${playlist.id}/tracks`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ` + music.developerToken,
                        'Music-User-Token': music.musicUserToken,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        data: [
                            {
                                id: song.id,
                                type: 'songs'
                            }
                        ]
                    })
                });

                if (response.status === 204) {
                    console.log("Song '" + songLookup + "' added to playlist successfully");
                    return Promise.resolve(true);
                } else {
                    errorHandler("Failed to add '" + songLookup + "' to playlist", 'addToPlaylist');
                    return Promise.resolve(false);
                }
            });
        });
    }

    return false;

}

export const removePlaylist = async () => {
    const music = window.MusicKit.getInstance();

    if (music.isAuthorized) {
        return getPlaylist().then(async playlist => {
            const response = await fetch(`https://api.music.apple.com/v1/me/library/playlists/${playlist.id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ` + music.developerToken,
                    'Music-User-Token': music.musicUserToken
                }
            });
            console.log('removePlaylist response', response);

            if (response.status === 204) {
                console.log('Playlist removed successfully');
                return Promise.resolve(true);
            } else {
                errorHandler('Failed to remove playlist', 'removePlaylist');
                return Promise.resolve(false);
            }
        });
    }

    return false;
}

/**
 * Removing from playlist is not supported by Apple Web API
 *
 * @param song
 * @returns {Promise<*|boolean|boolean>}
 */
export const removeFromPlaylist = async (song) => {
    const music = window.MusicKit.getInstance();

    if (music.isAuthorized) {
        return searchSong(song.artist_title).then(song => {

            getPlaylist().then(async playlist => {
                const response = await fetch(`https://api.music.apple.com/v1/me/library/playlists/${playlist.id}/tracks/${song.id}`, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `Bearer ` + music.developerToken,
                        'Music-User-Token': music.musicUserToken
                    }
                });
                console.log('removeFromPlaylist response', response);

                if (response.status === 204) {
                    console.log('Song removed from playlist successfully');
                    return Promise.resolve(true);
                } else {
                    errorHandler('Failed to remove song from playlist', 'removeFromPlaylist');
                    return Promise.resolve(false);
                }
            });
        });
    }
    return false;
}


export const createPlaylist = async () => {
    const music = window.MusicKit.getInstance();

    if (music.isAuthorized) {
        const response = await fetch('https://api.music.apple.com/v1/me/library/playlists', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ` + music.developerToken,
                'Music-User-Token': music.musicUserToken,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                attributes: {
                    name: process.env.REACT_APP_APPLE_PLAYLIST_NAME
                }
            })
        });

        if (response.status === 201) {
            console.log('Playlist created successfully');
            const responseBody = await response.json();
            const playlist = responseBody.data[0];
            return Promise.resolve(playlist);
        } else {
            errorHandler('Failed to create playlist', 'createPlaylist');
            return Promise.resolve(false);
        }
    }
    return false;
};

const errorHandler = (msg, functionName) => {
    try {
        console.error('MusicKit.js Error :: ' + msg + ' in ' + functionName);
    } catch (err) {
        console.error('MusicKit.js Error :: ' + err);
    }
}
