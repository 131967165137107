import React, {useEffect, useState} from 'react'
import 'react-h5-audio-player/lib/styles.css';
import AudioPlayer from 'react-h5-audio-player';
import {connect, useDispatch} from "react-redux";
import {setPlayerSong} from "../../store/actions/Actions";

function HitlabPlayer({src, title, cover, playlist}) {

    const dispatch = useDispatch();
    const [playingSong, setPlayingSong] = useState('playingSong')

    useEffect(() => {
        //console.log('playingSong - Has changed', playingSong)
    },[playingSong])

    // get the index of the current song
    let index = Array.isArray(playlist) ? playlist.findIndex((song) => song.song_url === src) : 0 ;

    function nextSong(){
        if(index < playlist.length - 1){
            dispatch(setPlayerSong(playlist[index + 1], playlist));
        } else {
            dispatch(setPlayerSong(playlist[0], playlist));
        }
    }

    function previousSong(){
        if(index > 0){
            dispatch(setPlayerSong(playlist[index - 1], playlist));
        }else{
            dispatch(setPlayerSong(playlist[playlist.length - 1], playlist));
        }
    }

    const Player = () => (
        <div className="flex">

            {cover
                ? <img src={cover} alt={title} className="w-24 h-24 object-scale-down"/>
                : <img src="/images/logo/logo-hitlab.svg" className="w-24 h-24 object-scale-down grayscale p-2 opacity-50" alt="placeholder"/>
            }

            <AudioPlayer
                src={src}
                header={title}
                showSkipControls={true}
                onEnded={() => {
                    nextSong();
                }}
                onClickNext={() => {
                    nextSong();
                }}
                onClickPrevious={() => {
                    previousSong();
                }}
            />
        </div>
    );

    return Player();
}

export default connect(state => ({
    playingSong: state.playerSong,//new
    //old
    isLoaded: state.playerSong.isLoaded,
    src: state.playerSong.src,
    title: state.playerSong.title,
    cover: state.playerSong.cover,
    playlist: state.playerSong.playlist,
    isAnalyzing: state.playerSong.isAnalyzing
}))(HitlabPlayer)

