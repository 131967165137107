import React, {useEffect, useRef, useState} from 'react'
import 'react-h5-audio-player/lib/styles.css';
import {connect, useDispatch} from "react-redux";
import {addSong, setPlayerSong, songsRedraw} from "../../store/actions/Actions";
import {addToPlaylist, getPlaylist, getSongsFromPlaylist, isAutoplayEnabled, searchSong} from "../../libraries/MusicKit";
import {addCatalog} from "../../libraries/DnaApi";
import PlayerLoader from "../loaders/PlayerLoader";
import {setMusicKitPlaylistMeta, setMusicKitPlaylistTracks} from "../../store/actions/MusicKitActions";


function ApplePlayer({songArtistTitle, playlist, playingSong, playerState, playerQueue}) {

    const music = window.MusicKit.getInstance();
    const audio = useRef();

    const dispatch = useDispatch();
    const [playbackTime, setPlaybackTime] = useState(0);
    const [isAppleAuthorized, setIsAppleAuthorized] = useState(false);
    const [isMuted, setIsMuted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [parentId, setParentId] = useState(null);
    const [hitlabSong, setHitlabSong] = useState(null);
    const [appleSong, setAppleSong] = useState(null);


    useEffect(() => {
        setIsLoading(true);

        if (music) {
            /* Dev settings */
            music.stop();

            if (!isAutoplayEnabled()) {
                /**
                 * Important hack for Safari
                 */
                setIsMuted(true);
                music.mute();
            }
            setIsAppleAuthorized(music.isAuthorized);
            setIsLoading(false);

            /**
             * Playback - Events
             */
            music.addEventListener('playbackProgressDidChange', onPlaybackProgressDidChange);

            /**
             * Now Playing - Events
             */
            music.addEventListener('nowPlayingItemDidChange', onNowPlayingItemDidChange);

            /**
             * Queue - Events
             */
            music.addEventListener('queueIsReady', onQueueIsReady);
            music.addEventListener('queueItemsDidChange', onQueueItemsDidChange);
            music.addEventListener('queuePositionDidChange', onQueuePositionDidChange);
        } else {
            //todo: restart counter ?
            console.error("MusicKit library hasn't started on time");
        }


        return () => {
            //clearTimeout(t);

            if (music) {
                music.removeEventListener("playbackProgressDidChange", onPlaybackProgressDidChange);
                music.removeEventListener("nowPlayingItemDidChange", onNowPlayingItemDidChange);
                music.removeEventListener("queueIsReady", onQueueIsReady);
                music.removeEventListener("queueItemsDidChange", onQueueItemsDidChange);
                music.removeEventListener("queuePositionDidChange", onQueuePositionDidChange);

                music.cleanup();
            }
        }
    }, []);

    useEffect(() => {
        //console.log('hitlabSong => ', hitlabSong);
        if (hitlabSong) {
            audio.current.play();
            setIsLoading(false);
        } else {
            audio.current.pause();
        }
    }, [hitlabSong])

    useEffect(() => {
        //console.log('appleSong => ', appleSong);
        if (appleSong) {
            setIsLoading(false);
        } else {
            music.stop();
        }
    }, [appleSong])

    useEffect(() => {
        //console.log('playingSong => ', playingSong);
        if (playingSong.id) {
            setIsLoading(true);

            if (playingSong.parentId) {
                setParentId(playingSong.parentId);
            }

            if (playingSong.type == 'song') {
                setAppleSong(null);
                setHitlabSong(playingSong.song_url);
            } else {
                setHitlabSong(null);
                playSong(playingSong.title);
            }
        }
    }, [playingSong]);

    useEffect(() => {
        //console.log('======> ApplePlayer isAppleAuthorized : ' + isAppleAuthorized);
        let poll = null;
        if (isAppleAuthorized) {
            getPlaylist().then((playlist) => {
                dispatch(setMusicKitPlaylistMeta(playlist));

                getSongsFromPlaylist().then(tracks => {
                    dispatch(setMusicKitPlaylistTracks(tracks));
                });
            });

            poll = setInterval(() => {
                getSongsFromPlaylist().then(tracks => {
                    dispatch(setMusicKitPlaylistTracks(tracks));
                });
            }, 15000);
        } else {
            clearTimeout(poll);
        }

        return () => {
            clearTimeout(poll);
        }
    }, [isAppleAuthorized]);

    /**
     * Playback Events
     */
    const onPlaybackProgressDidChange = () => {
        if (music.currentPlaybackTimeRemaining) {
            setPlaybackTime(music.currentPlaybackTime);
        }
    };

    /**
     * NowPlaying Events
     */
    const onNowPlayingItemDidChange = () => {
        //console.log('=> nowPlayingItemDidChange', music);
        setIsLoading(false);
    };

    /**
     * Queue Events
     */
    const onQueueIsReady = (e) => {
        //console.log('=> onQueueIsReady', e);
    };

    const onQueueItemsDidChange = (e) => {
        //console.log('=> queueItemsDidChange', e);
    };

    const onQueuePositionDidChange = (e) => {
        //console.log('=> queuePositionDidChange', e);
    };

    const playSong = (artist_title) => {

        //0: 'none', 1: 'loading', 2: 'playing', 3: 'paused', 4: 'stopped', 5: 'ended', 6: 'seeking', 8: 'waiting', 9: 'stalled', 10: 'completed'
        if (music && music.playbackState && [1].includes(music.playbackState)) {
            return; //skip
        }

        if (artist_title) {
            searchSong(artist_title).then(async appleSong => {
                if (appleSong) {
                    if (music.queueIsEmpty) {
                        //console.log('playSong music.queueIsEmpty', appleSong);
                        music.setQueue({song: appleSong.id}).then(() => {
                            music.skipToNextItem();
                        });
                    } else {
                        //console.log('playSong music.playNext', appleSong, music.nowPlayingItem);
                        if (!music.nowPlayingItem || (appleSong && music && music.nowPlayingItem && appleSong.id != music.nowPlayingItem.id)) {
                            await music.playNext({song: appleSong.id}).then(() => {
                                music.skipToNextItem();
                            });
                        }
                    }
                }

                setAppleSong(appleSong);
            }).catch(e => {
                console.error(e);
            });
        } else {
            console.error('artist - title not found');
        }
    }

    const play = () => {
        if (playingSong.type == 'song') {
            music.pause();
            audio.current.play();
        } else {
            audio.current.pause();
            if (music.nowPlayingItem) {
                console.log('player :: play :: clicked :: nowPlayingItem', music.nowPlayingItem);

                if (music.isPlaying) {
                    music.pause().then(() => {
                        music.play();
                    });
                } else {
                    music.play();
                }
            } else if (!music.queueIsEmpty) {
                console.log('player :: play :: clicked :: queueIsNotEmpty', music.queue.items)
                music.changeToMediaItem(music.queue.items[0]);
            } else {
                console.log('player :: play :: clicked :: do nothing')
            }
        }

    }

    const pause = () => {
        if (playingSong.type == 'song') {
            audio.current.pause();
        } else {
            if (music.isPlaying) {
                music.pause();
            }
        }
    }

    const stop = () => {
        if (playingSong.type == 'song') {
            audio.current.pause();
        } else {
            if (music.isPlaying) {
                music.stop();
            }
        }
    }

    const previous = () => {
        let index = Array.isArray(playerQueue) ? playerQueue.findIndex((song) => song.id === playingSong.id) : 0;
        let previousIndex = (index - 1) % playerQueue.length;//playerQueue.length - index - 1;
        if (previousIndex < 0) {
            previousIndex = (playerQueue.length - 2) - previousIndex;
        }

        if (playerQueue && playerQueue[previousIndex]) {
            if (playingSong.parentId) {
                playerQueue[previousIndex].parentId = playingSong.parentId;
            }
            dispatch(setPlayerSong(playerQueue[previousIndex], playerQueue));
        }
    }

    function next() {
        let index = Array.isArray(playerQueue) ? playerQueue.findIndex((song) => song.id === playingSong.id) : 0;
        let nextIndex = (index + 1) % playerQueue.length;

        if (playerQueue && playerQueue[nextIndex]) {
            if (playingSong.parentId) {
                playerQueue[nextIndex].parentId = playingSong.parentId;
            }
            dispatch(setPlayerSong(playerQueue[nextIndex], playerQueue));
        }
    }

    function seek(e) {
        const progressBar = document.getElementById("progress-bar");
        const seekPixel = e.clientX - e.target.offsetLeft;
        const percentagePixel = seekPixel / progressBar.getBoundingClientRect().width;
        const seekTime = Math.round(music.currentPlaybackDuration * percentagePixel);
        music.seekToTime(seekTime);
    }

    function mute() {
        if (isMuted || !music.volume) {
            setIsMuted(false);
            music.unmute();
        } else {
            setIsMuted(true);
            music.mute();
        }
    }


    const onClickLoginToApple = () => {
        if (!music.isAuthorized) {
            music.authorize().then(() => {
                setIsAppleAuthorized(music.isAuthorized)
            }).catch(e => {
                console.error(e);
            });
        }
    }

    const onClickLogoutFromApple = () => {
        if (music.isAuthorized) {
            music.unauthorize().then(() => {
                setIsAppleAuthorized(music.isAuthorized)
            }).catch(e => {
                console.error(e);
            });
        }
    }

    const onClickAddToShortlist = () => {
        if (playingSong) {
            if (playingSong.type === 'catalog') {
                addCatalog(playingSong.id)
                    .then((response) => {
                        dispatch(addSong(response));
                    });
            }
        }
    }

    const onClickAddToPlaylist = () => {
        if (songArtistTitle) {
            if (!music.isAuthorized) {
                music.authorize().then(() => {
                    addToPlaylist(songArtistTitle).then(r => {
                        //to nothing for now
                        //todo: dispatch newly added song updates to song cards
                        //todo: if it's a similar (aka : no parentId) then addToShortlist + addToPlaylist

                        getSongsFromPlaylist();
                    });

                }).catch(e => {
                    console.error(e);
                });
            } else {
                addToPlaylist(songArtistTitle).then(r => {
                    dispatch(songsRedraw(true));
                });
            }
        }
    }


    const onClickAppleMusicLogo = () => {
        let url = 'https://music.apple.com/us/library/all-playlists/';

        if (playlist) {
            url = 'https://music.apple.com/library/playlist/' + playlist.id;
        }
        window.open(url, "_blank", "noreferrer");
    }


    function formatTime(time) {
        if (!time) {
            return '';
        }
        const minutes = Math.floor(time / 60);
        const seconds = time - minutes * 60;
        return minutes + ':' + seconds.toString().padStart(2, '0');
    }

    return (
        <div className="flex flex-row bg-black text-white">
            <div className="flex-none w-20 h-20 sm:w-36 sm:h-36">
                {isLoading
                    ?
                    <PlayerLoader width={100} height={100} message="Fetching song data"/>
                    :
                    <>
                        {playingSong.type == 'catalog' && music && music.nowPlayingItem
                            ?
                            <div>
                                <img src={window.MusicKit.formatArtworkURL(music.nowPlayingItem.attributes.artwork, 150, 150)} alt={songArtistTitle}
                                     className="w-20 h-20 sm:w-36 sm:h-36"/>
                            </div>
                            :
                            <div>
                                {playingSong.cover ?
                                    <img src={playingSong.cover} alt={songArtistTitle}
                                         className="w-20 h-20 sm:w-36 sm:h-36"/>
                                    :
                                    <img src="/images/logo/logo-hitlab.jpg" alt={songArtistTitle}
                                         className="w-20 h-20 sm:w-36 sm:h-36 grayscale"/>
                                }
                            </div>
                        }
                    </>
                }
            </div>
            <div className="flex-grow">
                <div className="mx-2 mt-2">
                    <div className="h-20">
                        {playingSong.type == 'catalog' && music && music.nowPlayingItem
                            ?
                            <div>
                                <div className="text-xs sm:text-sm sm:text-lg font-bold truncate">{music.nowPlayingItem.attributes.name}</div>
                                <div className="text-xs sm:text-sm truncate">{music.nowPlayingItem.attributes.artistName}</div>
                            </div>
                            :
                            <div>
                                <div className="text-xs sm:text-sm sm:text-lg font-bold truncate">{playingSong.title}</div>
                                <div className="text-xs sm:text-sm truncate">{playingSong.artist}</div>
                            </div>
                        }
                    </div>
                    <div className="flex flex-wrap">
                        <div className="flex flex-nowrap">
                            <button className="m-1 sm:m-2 px-1 sm:text-2xl text-gray-200 hover:text-gray-400"
                                    id="apple-music-stop" onClick={previous}><i className="fa-solid fa-backward-step"></i></button>
                            <button className="m-1 sm:m-2 px-1 sm:text-2xl text-gray-200 hover:text-gray-400"
                                    id="apple-music-play" onClick={play}><i className="fa-solid fa-play"></i></button>
                            <button className="m-1 sm:m-2 px-1 sm:text-2xl text-gray-200 hover:text-gray-400"
                                    id="apple-music-stop" onClick={pause}><i className="fa-solid fa-pause"></i></button>
                            <button className="m-1 sm:m-2 px-1 sm:text-2xl text-gray-200 hover:text-gray-400"
                                    id="apple-music-stop" onClick={stop}><i className="fa-solid fa-stop"></i></button>
                            <button className="m-1 sm:m-2 px-1 sm:text-2xl text-gray-200 hover:text-gray-400"
                                    id="apple-music-stop" onClick={next}><i className="fa-solid fa-forward-step"></i></button>
                        </div>
                        <div className="grow">
                            {playingSong.type == 'catalog' && music && music.nowPlayingItem
                                ? <div className="flex flex-nowrap min-w-[160px]">
                                    <div id="progress-bar" className="w-full h-3 mt-3 sm:mt-5 bg-gray-200 rounded-full dark:bg-gray-700" onMouseDown={seek}>
                                        <div className="h-3 bg-gray-400 rounded-full" style={{width: music.currentPlaybackProgress * 100 + '%'}}>
                                            <div className="leading-none pl-2 bottom-4 text-slate-900 text-xs">{formatTime(playbackTime)}</div>
                                        </div>
                                    </div>
                                    <div className="leading-none pl-2 bottom-4 text-xs h-3 mt-3 sm:mt-5">{formatTime(music.currentPlaybackDuration)}</div>
                                    <div>
                                        <button className="m-1 sm:m-2 px-1 sm:text-2xl text-gray-200 hover:text-gray-400"
                                                id="apple-music-mute" onClick={mute}>
                                            {isMuted
                                                ? <i className="fa-solid fa-volume-xmark"></i>
                                                : <i className="fa-solid fa-volume-high"></i>
                                            }
                                        </button>
                                    </div>
                                </div>
                                :
                                <div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex-none">
                <div className="w-16 sm:w-24 mx-2 mt-2">
                    <div className="flex flex-row">
                        <button title="Visit Apple Music"
                                className="w-full"
                                onClick={onClickAppleMusicLogo}>
                            <img className="w-full" src="/images/logo/apple/music/icons/black-and-white/Apple_Music_Badge_wht_sm_091917.svg"/>
                        </button>
                    </div>
                    <div className="flex flex-row mb-2">
                        {isAppleAuthorized
                            ?
                            <div className="grow">
                                <button
                                    title="Logout from Apple Music"
                                    className="w-full flex-wrap rounded p-1 leading-none text-xs text-white bg-gray-400 hover:bg-red-500"
                                    onClick={onClickLogoutFromApple}>Logout
                                </button>
                            </div>
                            :
                            <div className="grow">
                                <button
                                    title="Login to Apple Music"
                                    className="w-full flex-wrap rounded p-1 leading-none text-xs text-white bg-gray-400 hover:bg-red-500"
                                    onClick={onClickLoginToApple}>Login on Apple
                                </button>
                            </div>
                        }
                    </div>
                    <div className="flex flex-row">
                        <div className="grow">
                            <button
                                title="Add playing song to playlist on Apple Music"
                                className="w-full flex-wrap rounded p-1 leading-none text-xs text-white bg-gray-400 hover:bg-red-500"
                                onClick={onClickAddToPlaylist}>Add to Playlist
                            </button>
                        </div>
                    </div>
                    <div className="flex flex-row">
                        <div className="grow">
                            <button
                                title="Add playing song to shortlist"
                                className="w-full flex-wrap rounded p-1 leading-none text-xs text-white bg-gray-400 hover:bg-red-500"
                                onClick={onClickAddToShortlist}>Add to Shortlist
                            </button>
                        </div>
                    </div>
                    <div className="flex flex-row">
                        <div className="grow">
                            <button
                                title="Manage playlist on Apple Music"
                                className="w-full flex-wrap rounded p-1 leading-none text-xs text-white bg-gray-400 hover:bg-red-500"
                                onClick={onClickAppleMusicLogo}>Manage Playlist
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <audio
                    src={hitlabSong}
                    loop={false}
                    autoPlay={false}
                    preload={'auto'}
                    ref={audio}
                >
                </audio>
            </div>
        </div>
    );
}


export default connect(state => {
    return {
        playingSong: state.playerSong,//new
        playerState: state.playerState,
        playerQueue: state.playerSong.playlist,
        //------------------------------------
        songArtistTitle: state.playerSong.title,
        isSongLoading: state.musicKit.isLoading,
        playlist: state.musicKit.playlist,
    };
})(ApplePlayer)

