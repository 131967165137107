import {connect, useDispatch} from "react-redux";
import SongCard from "../songs/SongCard";
import HitlabLoader from "../loaders/HitlabLoader";
import {getSongs} from "../../libraries/DnaApi";
import React, {useEffect, useState} from 'react';
import {setSongs} from "../../store/actions/Actions";
import {TContainer, TFlex} from "../template/TComponents";


function Dashboard({songs, paginator, songsRedraw}) {

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);

    const fetchSongs = async () => {
        setLoading(true);
        return getSongs(paginator)
            .then(async response => {
                dispatch(setSongs(response));
            }).finally(() => {
                setLoading(false);
            });
    }

    useEffect(() => {
        fetchSongs().then(r => {
        });
    }, []);

    return (
        <TFlex>
            <TContainer>
                <div className="flex flex-row-reverse">
                    {songs.length > 0 &&
                        <div className="px-2 pb-1 text-xs text-gray-400">Showing from {paginator.meta.from} to {paginator.meta.to} of {paginator.meta.total} shortlisted songs</div>
                    }
                </div>
                <div id="dashboard-songs" className="mb-32 h-full overflow-auto">
                    {loading
                        ? <div className="py-12"><HitlabLoader message="Loading your songs"/></div>
                        : (
                            !songs.length ?
                                <div className="py-12 text-center">Your list is empty</div>
                                :
                                songs.map((song) => (
                                    <SongCard song={song} key={song.id}/>
                                ))
                        )
                    }
                    <div className="flex flex-row-reverse">
                        {songs.length > 0 &&
                            <div className="px-2 text-xs text-gray-400">Showing from {paginator.meta.from} to {paginator.meta.to} of {paginator.meta.total} shortlisted
                                songs</div>
                        }
                    </div>
                    <p className="my-8 text-center text-sm font-bold text-gray-500">Discovered by Zeeky</p>
                </div>
            </TContainer>
        </TFlex>
    )
}


export default connect(state => {
    return {
        songsRedraw: state.songs.songsRedraw,
        songs: state.songs.data,
        paginator: state.songs.paginator
    };
})(Dashboard)