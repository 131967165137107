import React from 'react';
import {Amplify} from "aws-amplify";
import {getLabels} from "../../languages/language";
import {NavLink, useHistory, useLocation} from "react-router-dom";
import {LanguageContextConsumer} from "../context/LanguageContext";
import TDropdown, {TDropdownItem} from "./../template/TDropdown"
import SongSearch from "../search/SongSearch";
import Paginator from "../pagination/Paginator";
import ApplePlayer from "../players/ApplePlayer";
import {connect} from "react-redux";


const RenderDropdown = ({logoutHandler, currentUser}) => {

    const email = currentUser && currentUser.attributes && currentUser.attributes.email

    return (
        <TDropdown>
            {
                (closeDropdown => (
                    <React.Fragment>
                        <TDropdownItem as="span" style={{borderBottomWidth: "0.1em"}}>You are logged as <b
                            style={{fontWeight: "bold"}}>{email}</b></TDropdownItem>
                        <TDropdownItem as={NavLink} to="/logout" onClick={logoutHandler} exact>Sign Out</TDropdownItem>
                    </React.Fragment>
                ))
            }
        </TDropdown>
    )
}

const Main = ({children, language, user, logout}) => {
    const {currentUser, isLoaded} = user;
    const location = useLocation();
    const history = useHistory();
    const isLoginPage = location.pathname.includes('login');

    // const [isUserLoggedIn, setCurentUserLoggedIn] = useState(user.isLoaded)

    const {label_tagline} = getLabels(language);


    const logoutHandler = (e) => {
        e.preventDefault()
        Amplify.Auth.signOut().then(data => {
            //logout()
            history.push('/login')
        }).catch(e => {
            console.error(e)
        })
    }

    return (
        <React.Fragment>
            <div className="min-h-screen flex flex-col">
                <div role="banner" className="font-display bg-black text-white py-1 sm:p-2 sticky top-0 z-50">
                    <div className="flex justify-between items-center">
                        <NavLink to="/">
                            <img src="/images/logo/zeeky/Z-logo-blk_2.png" alt="Zeeky" className="h-10"/>
                        </NavLink>

                        {currentUser && !isLoginPage && <SongSearch/>}


                        <div className="z-10">
                            {!currentUser && !isLoginPage && <NavLink to="/login">Login</NavLink>}
                            {currentUser && <RenderDropdown currentUser={currentUser} logoutHandler={logoutHandler}/>}
                        </div>
                    </div>
                </div>

                <main className="mb-32 h-full">
                    {isLoaded ? children : <span>Loading...</span>}
                </main>

                <footer className="w-full bg-gray-300 text-gray-900 fixed bottom-0">
                    {currentUser && !isLoginPage &&
                        <>
                            <Paginator></Paginator>
                            <ApplePlayer></ApplePlayer>
                        </>
                    }

                </footer>

            </div>
        </React.Fragment>
    );
};

export default connect(state => {
    return {};
}, (props) => {
    return <LanguageContextConsumer Component={Main} {...props} />
})(Main)

