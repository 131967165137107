import * as actionTypes from "./ActionTypes";

export const setMusicKitPlaylistTracks = (tracks) => {
    return {
        type: actionTypes.MUSIC_KIT_PLAYLIST_SET_TRACKS,
        payload: {
            tracks: tracks
        }
    }
}

export const setMusicKitPlaylistMeta = (playlist) => {
    return {
        type: actionTypes.MUSIC_KIT_PLAYLIST_SET_META,
        payload: {
            playlist: playlist
        }
    }
}

export const setMusicKitPlaylistIsLoading = (isLoading) => {
    return {
        type: actionTypes.MUSIC_KIT_PLAYLIST_SET_IS_LOADING,
        payload: {
            isLoading: isLoading
        }
    }
}
export const setMusicKitIsAppleAuthorized = (isAppleAuthorized) => {
    return {
        type: actionTypes.MUSIC_KIT_PLAYLIST_SET_IS_APPLE_AUTHORIZED,
        payload: {
            isAppleAuthorized: isAppleAuthorized
        }
    }
}
