import * as actionTypes from "./../actions/ActionTypes"

const MUSIC_KIT = {
    count: 0,
    tracks: [],
    playlist: null,
    isLoading: false,
    isAppleAuthorized: false,
    forceRedraw: null
};

export default (state = MUSIC_KIT, action) => {
    switch (action.type) {
        case actionTypes.MUSIC_KIT_PLAYLIST_SET_META:
            return {
                ...state,
                playlist: action.payload.playlist
            }
        case actionTypes.MUSIC_KIT_PLAYLIST_SET_TRACKS:
            return {
                ...state,
                count: action.payload.tracks.length,
                tracks: action.payload.tracks
            }
        case actionTypes.MUSIC_KIT_PLAYLIST_SET_IS_LOADING:
            return {
                ...state,
                isLoading: action.payload.isLoading
            }
        case actionTypes.MUSIC_KIT_PLAYLIST_SET_IS_APPLE_AUTHORIZED:
            return {
                ...state,
                isAppleAuthorized: action.payload.isAppleAuthorized
            }
        default:
            return state;
    }
};