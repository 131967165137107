import * as actionTypes from "./../actions/ActionTypes"

const PLAYER_SONG = {
    isLoaded: false,
    parentId: null,
    id: null,
    type: null,
    cover: null,
    title: null,
    playlist: [],
    playlistIndex: 0,
    nextSong: null,
    isAnalyzing: false,
};

export default (state = PLAYER_SONG, action) => {
    switch (action.type) {
        case actionTypes.SET_PLAYER_SONG:
            let playerSong = {
                isLoaded: true,
                parentId: action.payload.playerSong.parentId,
                id: action.payload.playerSong.id,
                type: action.payload.playerSong.type,
                cover: action.payload.playerSong.cover,
                title: action.payload.playerSong.artist + ' - ' + action.payload.playerSong.title,
                playlist: action.payload.playlist,
                playlistIndex: 0,
                nextSong: action.payload.playlist[0],
                isAnalyzing: false // action.payload.filter(song => song.attributes.state !== "analyzed" && !song.attributes.score).length > 0
            }

            if (playerSong.type == 'song') {
                playerSong['song_url'] = action.payload.playerSong.song_url
            }

            return playerSong;
        case actionTypes.SET_NEXT_SONG:
            return {
                ...state,
                playlistIndex: state.playlistIndex++,
                nextSong: state.playlist[state.playlistIndex]
            };
        default:
            return state;
    }
};