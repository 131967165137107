import * as actionTypes from "./../actions/ActionTypes"

const PLAYER_STATE = {
    isPlaying: false,
    playbackTime: 0,
    playbackTimeRemaining: 0,
    playbackProgress: 0,
    playbackDuration: 0,
};

export default (state = PLAYER_STATE, action) => {
    switch (action.type) {
        case actionTypes.SET_PLAYER_STATE:
            return {
                isPlaying: action.payload.isPlaying,
                playbackProgress: action.payload.playbackProgress,
                playbackTime: action.payload.playbackTime,
                playbackTimeRemaining: action.payload.playbackTimeRemaining,
                playbackDuration: action.payload.playbackDuration
            }
        default:
            return state;
    }
};